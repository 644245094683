<template>
    <div class="bg-black block md:hidden">
        <nav class="shadow flex justify-around w-screen text-white">
            <div v-for="(item, index) in links" :key="index" class="flex-initial my-auto w-1/4">
                <router-link :to="item.to" v-slot="{ isActive }">
                    <div class="flex justify-center items-center py-8 sm:py-6">
                        <img class="w-6 h-6 sm:w-8 sm:h-8" :src="isActive ? item.active_icon: item.icon">
                    </div>
                </router-link>
            </div>
        </nav>
    </div>
    
    <div class="bg-scorelitgray hidden md:block">
        <div class="flex justify-between items-center">
            <div class="flex-initial pl-4 md:pl-10 my-auto">
                <router-link active-class="" exact-active-class="" :to="{name: 'dashboard'}">
                    <img class="h-6 sm:h-8" src="@/assets/images/logo/logo-white.svg" alt="Scorelit logo">
                </router-link>
            </div>
                
            <nav class="px-4 shadow flex justify-around h-full md:pr-10 text-white py-6 space-x-6 lg:space-x-10">
                <div v-for="(item, index) in links" :key="index" class="flex-initial flex justify-center">
                    <router-link :to="item.to" v-slot="{ isActive }">
                        <div class="flex group justify-center items-center">
                            <img class="w-5 h-5" :src="isActive ? item.active_icon: item.icon">
                            <span class="ml-2 font-semibold text-sm group-hover:text-gray-400 transition duration-200" :class="isActive ? 'text-white' : 'text-gray-300 active:text-white'">{{ item.text }}</span>
                        </div>
                    </router-link>
                </div>
            </nav>

            

        </div>
    </div>
</template>

<script>
import useI18n from "@/modules/globalI18n";

export default {
    name: "Navigation",
    setup() {
        return useI18n({
            componentPrefix: "Navigation"
        });
    },
    computed :{
        links() {
            return [
                {
                    text: this.t('dashboard'),
                    to: { name: 'dashboard' },
                    icon: require('@/assets/icons/menu/shield.svg'),
                    active_icon: require('@/assets/icons/menu/shield_active.svg'),
                },
                {
                    text: this.t('reviews'),
                    to: { name: 'reviews' },
                    icon: require('@/assets/icons/menu/clipboard.svg'),
                    active_icon: require('@/assets/icons/menu/clipboard_active.svg'),
                },
                {
                    text: this.t('matches'),
                    to: { name: 'matches' },
                    icon: require('@/assets/icons/menu/camera.svg'),
                    active_icon: require('@/assets/icons/menu/camera_active.svg'),
                },
                {
                    text: this.t('teams'),
                    to: { name: 'teams' },
                    // todo: icon
                    icon: require('@/assets/icons/menu/team.svg'),
                    active_icon: require('@/assets/icons/menu/team_active.svg'),
                },
                {
                    text: this.t('profile'),
                    to: { name: 'profile' },
                    icon: require('@/assets/icons/menu/user.svg'),
                    active_icon: require('@/assets/icons/menu/user_active.svg'),
                },
            ]
        },
        
    },
}
</script>

<style scoped>

</style>