<template>
    <div :class="fullscreen? 'grid-full-screen': ''"  class="grid-container bg-black">
        <div v-if="!fullscreen" class="grid-nav">
            <Navigation />
        </div>

        <main class="grid-main overflow-auto scrolling-touch">
            <div class="h-full" :class="unbounded ? '': 'container mx-auto px-2 sm:px-4 md:px-10'">
                <router-view v-slot="{ Component }"> 
                    <transition name="fade" mode="out-in">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </div>
        </main>
    </div>
</template>

<script>
import Navigation from "@/components/Navigation";

export default {
    name: "Dashboard",
    components: {
        Navigation,
    },
    data(){
        return {
            transitionName: 'fade',
        }
    },
    watch: {
        // '$route' (to, from) {
        //     const toDepth = to.path.split('/').length
        //     const fromDepth = from.path.split('/').length
        //     this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
        // },
    },
    computed: {
        fullscreen: function() {
            return this.$route.meta.fullscreen;
        },
        unbounded: function (){
            return this.$route.meta.unbounded;
        }
    }
}
</script>

<style scoped>
    .grid-nav {
        grid-area: nav;
    }
    .grid-main {
        grid-area: main;
    }

    .grid-full-screen {
        grid-template-rows: auto !important; 
        grid-template-areas: 
            "main" !important;
    }

    .grid-container {
        display: grid;
        grid-template-columns: auto ;
        grid-template-rows: auto 80px;
        height: 100%;
        grid-template-areas: 
            "main"
            "nav";
    }

    @media (min-width: 768px) { 
        .grid-container {
            grid-template-columns: auto;
            grid-template-rows: 80px auto;

            grid-template-areas: 
                "nav"
                "main";
        }
    }
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.25s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .slide-left-enter-active,
    .slide-left-leave-active,
    .slide-right-enter-active,
    .slide-right-leave-active {
        transition-duration: 0.5s;
        transition-property: height, opacity, transform;
        transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
        overflow: hidden;
    }

    .slide-left-enter-from,
    .slide-right-leave-active {
        opacity: 0;
        transform: translate(2em, 0);
    }

    .slide-left-leave-active,
    .slide-right-enter-from {
        opacity: 0;
        transform: translate(-2em, 0);
    }

</style>